import React, { useState, useEffect, useContext } from "react";

import { useFormik } from "formik";
import { Doctorregisterschemas } from "../../Schemas/index";
// import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
// import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import { GlobalContext } from "../../Context/GlabalContext";
// import Location from "../../Assets/Images/location_ic@3x.png";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import axios from "../../Constant/ApiUrl";
import { DOCTORREGISTER, MASTERDATAAPI } from "../../Constant/Apiconstant";
import { Icons } from "../../Constant/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Colors } from "../../Constant/Colors";
import Loader from "../../Constant/Loader";
import { DOCTORGETPROFILE } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import { ProfessionalInfoApi } from "../../Constant/Apiconstant";
import Fetchdata from "../../Constant/Fetchdata";
import moment from "moment";

const ProfessionalInfo = () => {
  const [address, setAddress] = useState("");

  const navigate = useNavigate();
  const { Docdata } = useContext(GlobalContext);

  // console.log(Docdata.image, " Docdata.image");
  const { steps, setsteps, DoctorRegisterdata } = useContext(GlobalContext);
  const formattedDate = moment(Docdata.date, "DD-MM-YYYY").format("DD-MM-YYYY");
  // console.log(formattedDate, "Docdata");
  const [masterdata, setmasterdata] = useState([]);
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  // console.log(country, "country");
  const [postalcode, setpostalcode] = useState("");
  const [doctorBook, setDoctorBook] = useState();
  const [loading, setloading] = useState(false);

  const getuniqueid = () => {
    return Math.random().toString(36).substring(2, 10);
  };
  //   const [licencedata, setlicencedata] = useState([]);

  // Simulating data from API
  //   const licenceDataFromBackend = doctorBook.licence.map((licence) => ({
  //     country: licence.country,
  //     licenceAuthority: licence.licenceAuthority,
  //     licensingNumber: licence.licensingNumber,
  //     isApporovedByAdmin: false,
  //     _id: licence._id || "", // Assuming _id can be empty or undefined
  //   }));

  //   useEffect(() => {
  //     // Initialize with backend data if needed
  //     const initialLicenceData = licenceDataFromBackend.map((licence) => ({
  //       id: licence._id,
  //       country: licence.country || "",
  //       licenceAuthority: licence.licenceAuthority || "",
  //       licensingNumber: licence.licensingNumber || "",
  //     }));
  //     setlicencedata(initialLicenceData);
  //   }, []);

  const [licencedata, setlicencedata] = useState([
    {
      country: "",
      id: getuniqueid(),
      licenceAuthority: "",
      licensingNumber: "",
    },
  ]);
  //   console.log(Docdata, "Docdata");

  // console.log(DoctorRegisterdata);
  const handleAddressChange = (newAddress) => {
    setAddress(newAddress);
  };
  const handleAddressBlur = () => {
    handleBlur("Address");
  };
  const header = Headers();
  const initialValues = {
    Speciality: "",
    firstName: DoctorRegisterdata.FullName,
    lastName: DoctorRegisterdata.LastName,
    email: DoctorRegisterdata.email,
    role: "physician",
    password: DoctorRegisterdata.password,
    gender: DoctorRegisterdata.gender,
    age: DoctorRegisterdata.age,
    dob: DoctorRegisterdata.date,
    faxNumber: DoctorRegisterdata.FaxNumber,
    phoneNumber: DoctorRegisterdata.Mobile,
    location: "",
    address: "",
    city: "",
    postalCode: "",
    state: "",
    country: "",
    lat: "",
    lng: "",
    image: DoctorRegisterdata.image,
    qualification: "",
    totalExperience: "",
    deviceType: "web",
    deviceToken: "37563gfcebfgrehfgcevj",
    licence: JSON.stringify(licencedata),
    userTimeZone: "Africa/Dar_es_Salaam",
  };

  useEffect(() => {
    if (doctorBook?.speciality) {
      setFieldValue("Speciality", doctorBook?.speciality);
    }
    if (doctorBook?.totalExperience) {
      setFieldValue("totalExperience", doctorBook?.totalExperience);
    }
    if (doctorBook?.qualification) {
      setFieldValue("qualification", doctorBook?.qualification);
    }
    // setFieldValue("qualification", doctorBook?.qualification);
    // setFieldValue("totalExperience", doctorBook?.totalExperience);
    setFieldValue("address", doctorBook?.basicInfo?.address);
    setFieldValue("location", doctorBook?.basicInfo?.location);
    setFieldValue("city", doctorBook?.basicInfo?.city);
    setFieldValue("state", doctorBook?.basicInfo?.state);
    setFieldValue("postalCode", doctorBook?.basicInfo?.postalCode);
    setCity(doctorBook?.basicInfo?.city);
    setAddress(doctorBook?.basicInfo?.location);
    setpostalcode(doctorBook?.basicInfo?.postalCode);
    setState(doctorBook?.basicInfo?.state);
    setlicencedata(doctorBook?.licence);
  }, [doctorBook]);

  // console.log(doctorBook);

  const onSubmit = async () => {
    setloading(true);
    const formdata = new FormData();
    formdata.append("firstName", Docdata.FullName);
    formdata.append("lastName", Docdata.LastName);
    formdata.append("role", doctorBook?.basicInfo?.role);
    formdata.append("gender", Docdata.gender);
    formdata.append("dob", formattedDate);
    formdata.append("faxNumber", Docdata.FaxNumber);
    formdata.append("phoneNumber", Docdata.Mobile);
    formdata.append("image", Docdata.image);
    formdata.append("location", address);
    formdata.append("address", values.address);
    formdata.append("city", city);
    formdata.append("postalCode", postalcode);
    formdata.append("state", state);
    formdata.append("country", country);
    formdata.append("lat", doctorBook?.basicInfo?.coordinates.lat);
    formdata.append("lng", doctorBook?.basicInfo?.coordinates.lng);
    formdata.append("speciality", values.Speciality);
    formdata.append("qualification", values.qualification);
    formdata.append("totalExperience", values.totalExperience);
    // formdata.append("country", country);
    formdata.append("licence", JSON.stringify(licencedata));
    try {
      const res = await axios.post(ProfessionalInfoApi, formdata, header);
      if (res.status === 200 && res.data.status === "success") {
        if (res.data.data.isCompleteProfile) {
          navigate("/myprofile");
        } else {
          navigate("/AddBankAccount");
        }
      }
      setloading(false);
      // console.log(res);
    } catch (error) {
      console.log(error);
      setloading(false);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: Doctorregisterschemas,
    onSubmit,
  });

  //   console.log(licencedata, "sachii");

  const handleSelect = async (selectedAddress) => {
    setFieldValue("address", selectedAddress);
    try {
      const results = await geocodeByAddress(selectedAddress);
      const { lat, lng } = await getLatLng(results[0]);
      setFieldValue("lat", lat);
      setFieldValue("lng", lng);
      setAddress(selectedAddress);
      setFieldValue("location", selectedAddress);
      // console.log(selectedAddress);
      const addressComponents = results[0].address_components;
      const cityComponent = addressComponents.find((component) =>
        component.types.includes("locality")
      );
      const stateComponent = addressComponents.find((component) =>
        component.types.includes("administrative_area_level_1")
      );
      const countryComponent = addressComponents.find((component) =>
        component.types.includes("country")
      );
      const postalCodeComponent = addressComponents.find((component) =>
        component.types.includes("postal_code")
      );
      setCity(cityComponent?.long_name || "");
      setState(stateComponent?.long_name || "");
      setCountry(countryComponent?.long_name || "");
      setpostalcode(postalCodeComponent?.long_name || "");
      handleChange({
        target: {
          name: "City",
          value: cityComponent?.long_name || "",
        },
      });
      handleChange({
        target: {
          name: "State",
          value: stateComponent?.long_name || "",
        },
      });
      handleChange({
        target: {
          name: "Country",
          value: countryComponent?.long_name || "",
        },
      });
    } catch (error) {
      console.error(error);
    }
  };

  // console.log(state);
  const Speciality = async () => {
    try {
      const res = await axios.get(MASTERDATAAPI, { hospital_id: "" });
      // console.log(res);
      if (res.status == 200 && res.data.status == "success") {
        setmasterdata(res.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // setFieldValue("country", country);
    setFieldValue("state", state);
    setFieldValue("city", city);
    setFieldValue("postalCode", postalcode);
  }, [city, state, postalcode, country]);

  useEffect(() => {
    Speciality();
  }, [steps]);

  const handleadd = () => {
    setlicencedata((prev) => [
      ...prev,
      {
        id: getuniqueid(),
        country: "",
        licenceAuthority: "",
        licensingNumber: "",
      },
    ]);
  };

  const handledelete = (item) => {
    // console.log(item);
    let newdata = licencedata.filter((element) => element.id !== item.id);
    // console.log(newdata);
    setlicencedata(newdata);
  };

  const handleFieldChange = (index, fieldName, value) => {
    setlicencedata((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
      )
    );
  };

  const handleFieldChange2 = (index, fieldName, value) => {
    setlicencedata((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
      )
    );
  };

  const handleFieldChange3 = (index, fieldName, value) => {
    setlicencedata((prev) =>
      prev.map((item, i) =>
        i === index
          ? {
              ...item,
              [fieldName]: value,
            }
          : item
      )
    );
  };

  const { response } = Fetchdata(DOCTORGETPROFILE, {}, "GET");

  useEffect(() => {
    if (response?.data) {
      setDoctorBook(response.data);
    }
  }, [response]);

  return (
    <>
      <div className="">
        <div className="">
          <div className="Profile-extra-div">
            <div className="Profile-main-Your">
              <span style={{ cursor: "pointer" }} onClick={() => setsteps(1)}>
                {Icons.backarrowblack}
              </span>
              <h6 className="mt-2">Step {steps} to 2</h6>
              <span className="Order-history-span">
                Professional Information
              </span>
              <span className="Upload-profile">
                Enter your professional information below to create your profile
                profile.
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="second-div mt-4">
            <div className="Health-input-div-main">
              <div className="hoooo-so-hoo mt-5">
                <div>
                  <div className="Yes-and-No-name">
                    <div className="Unit-kg-selact">
                      <span className="">Speciality</span>
                      <select
                        className="Volvo-Speciality-value mt-2"
                        name="Speciality"
                        id="Speciality"
                        value={values.Speciality}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      >
                        <option value="" disabled selected>
                          Select Speciality
                        </option>
                        {masterdata?.specialities?.map((speciality) => {
                          return (
                            <option value={speciality.name}>
                              {speciality.specialityName}
                            </option>
                          );
                        })}
                      </select>
                      {errors.Speciality && touched.Speciality ? (
                        <p className="bmi-Weight mt-2">{errors.Speciality}</p>
                      ) : null}
                    </div>
                  </div>
                  <div className="Unit-kg-selact mt-3">
                    <span className="">Qualification</span>
                    <select
                      className="Volvo-Speciality-value w-100 mt-2"
                      name="qualification"
                      id="Qualification"
                      value={values.qualification}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled selected>
                        Select Qualification
                      </option>
                      {masterdata?.qualification?.map((qualification) => {
                        return (
                          <option value={qualification.name}>
                            {qualification.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.qualification && touched.qualification ? (
                      <p className="bmi-Weight mt-2">{errors.qualification} </p>
                    ) : null}
                  </div>
                  <div className="Unit-kg-selact w mt-3">
                    <span className="">TotalExperience</span>
                    <select
                      className="Volvo-Speciality-value mt-2"
                      name="totalExperience"
                      id="Qualification"
                      value={values.totalExperience}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled selected>
                        Enter Total Experience
                      </option>
                      {Array.from({ length: 50 }).map((element, index) => {
                        return <option value={index + 1}>{index + 1}</option>;
                      })}
                    </select>
                    {errors.totalExperience && touched.totalExperience ? (
                      <p className="bmi-Weight mt-2">
                        {errors.totalExperience}{" "}
                      </p>
                    ) : null}
                  </div>
                </div>

                <div>
                  <h4 className="mt-3">Current Practice Address</h4>
                  <div className="Location-main-place-div mt-3">
                    <span>Location (Street Address)</span>
                    <div className="mt-2 Information-Street-main-div">
                      <PlacesAutocomplete
                        value={address}
                        onChange={handleAddressChange}
                        onSelect={handleSelect}
                        onBlur={handleAddressBlur}
                        name="Address"
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="d-flex flex-column">
                            <input
                              className="mene-change-kiya-hai"
                              {...getInputProps({
                                placeholder: "Enter Location",
                              })}
                            />
                            <div style={{ zIndex: 1000 }}>
                              {loading ? <div>Loading...</div> : null}
                              {suggestions.map((suggestion) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#41b6e6"
                                    : "#fff",
                                };
                                return (
                                  <div
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    {errors.location && touched.location ? (
                      <p className="bmi-Weight mt-2">{errors.location} </p>
                    ) : null}
                  </div>
                  <div className="BMI-MAin-input-div mt-4">
                    <span className="">
                      Address (Ex. Nuilding, Floor, Room, Cabin)
                    </span>
                    <div className="BMI-MAin-input mt-2">
                      <input
                        className="Bim-Md-main-input-css"
                        type="text"
                        placeholder="Enter Address"
                        name="address"
                        value={values.address}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                    {errors.address && touched.address ? (
                      <p className="bmi-Weight mt-2">{errors.address} </p>
                    ) : null}
                  </div>
                  <div className="Unit-kg-selact mt-3">
                    <span className="">City</span>
                    <select
                      className="Volvo-Speciality-value mt-2"
                      name="city"
                      id="City"
                      value={city}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled selected>
                        Select
                      </option>
                      <option value={city}>{city}</option>
                    </select>
                    {errors.city && touched.city ? (
                      <p className="bmi-Weight mt-2">{errors.city} </p>
                    ) : null}
                  </div>
                  <div className="BMI-MAin-input-div mt-4">
                    <span className="">Postal Code</span>
                    <div className="BMI-MAin-input mt-2">
                      <input
                        className="Bim-Md-main-input-css"
                        type="text"
                        placeholder="Enter Code"
                        name="postalCode"
                        value={postalcode}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="Unit-kg-selact mt-3">
                    <span className="">Province/State</span>
                    <select
                      className="Volvo-Speciality-value mt-2"
                      name="state"
                      id="Province"
                      value={state}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    >
                      <option value="" disabled selected>
                        Select Province/State
                      </option>
                      <option value={state}>{state}</option>
                    </select>
                    {errors.state && touched.state ? (
                      <p className="bmi-Weight mt-2">{errors.state} </p>
                    ) : null}
                  </div>
                </div>

                <div>
                  <h4 className="mt-3">Add Licence</h4>
                  {licencedata?.map((item, index) => {
                    return (
                      <>
                        <div className="Unit-kg-selact mt-3">
                          <div className="w-100 d-flex justify-content-between">
                            <span className="">Country</span>
                            {index > 0 ? (
                              <span
                                style={{
                                  backgroundColor: Colors.blue,
                                  color: "#ffffff",
                                  fontSize: "15px",
                                  width: "20px",
                                  height: "20px",
                                  borderRadius: "50%",
                                  cursor: "pointer",
                                }}
                                className="d-flex align-items-center justify-content-center"
                                onClick={() => handledelete(item)}
                              >
                                X
                              </span>
                            ) : null}
                          </div>
                          <select
                            className="Volvo-Speciality-value mt-2"
                            name="country"
                            id="Country"
                            value={item.country}
                            onChange={(e) =>
                              handleFieldChange(
                                index,
                                "country",
                                e.target.value
                              )
                            }
                            onBlur={handleBlur}
                          >
                            <option value="" disabled selected>
                              Select Country
                            </option>
                            {masterdata?.licence?.map((licence) => {
                              return (
                                <option value={licence.countryName}>
                                  {licence.countryName}
                                </option>
                              );
                            })}
                          </select>
                          {errors.country && touched.country ? (
                            <p className="bmi-Weight mt-2">{errors.country} </p>
                          ) : null}
                        </div>
                        <div className="Unit-kg-selact mt-4">
                          <span className="">Licensing Authority</span>
                          <select
                            className="Volvo-Speciality-value mt-2"
                            name="licenceAuthority"
                            id="Licensing"
                            value={item.licenceAuthority}
                            // onChange={handleChange}
                            onChange={(e) =>
                              handleFieldChange2(
                                index,
                                "licenceAuthority",
                                e.target.value
                              )
                            }
                            onBlur={handleBlur}
                          >
                            <option value="" disabled selected>
                              Select licence Country
                            </option>
                            {masterdata?.licence
                              ?.find((i) => i.countryName == item.country)
                              ?.authorityName?.map((authorityname) => {
                                return (
                                  <option value={authorityname.name}>
                                    {authorityname.name}
                                  </option>
                                );
                              })}
                          </select>
                          {errors.licence && touched.licence ? (
                            <p className="bmi-Weight mt-2">{errors.licence} </p>
                          ) : null}
                        </div>
                        <div className="Unit-kg-selact mt-4">
                          <span className="">Licence No.</span>
                          <div className="BMI-MAin-input mt-2">
                            <input
                              className="Bim-Md-main-input-css"
                              type="number"
                              placeholder="Enter Licence number"
                              name="licensingNumber"
                              onChange={(e) =>
                                handleFieldChange3(
                                  index,
                                  "licensingNumber",
                                  e.target.value
                                )
                              }
                              value={item.licensingNumber}
                              // onChange={handleChange}
                              onBlur={handleBlur}
                            />
                          </div>
                          {errors.LicensingNo && touched.LicensingNo ? (
                            <p className="bmi-Weight mt-2">
                              {errors.LicensingNo}{" "}
                            </p>
                          ) : null}
                        </div>
                      </>
                    );
                  })}
                  <div className="">
                    <div
                      className="LicensingNo-add-more "
                      onClick={() => handleadd()}
                    >
                      <span>{Icons.addbluebtn}</span>
                      <span
                        className="New-ard-add-span "
                        style={{ cursor: "pointer" }}
                      >
                        Add More
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mt-4 mb-4">
                <button
                  onClick={onSubmit}
                  type="submit"
                  className="Submit-Main-btn-Bim"
                >
                  Update
                </button>
              </div>
            </div>
          </form>
          {/* <div>
            <img className="Medical-next-blue" src={Blue} alt="" />
          </div> */}
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default ProfessionalInfo;
