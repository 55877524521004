// auth api endpoints...........................
export const PATIENTLOGIN = "/patient/login";
export const PATIENTREGISTERAPI = "/patient/register";
export const PATIENTCHANGEPASS = "/patient/changePassword";
export const PATIENTFORGOTPASS = "/patient/forgotpassword";
export const OTPVERIFY = "/patient/otpverify";
export const RESENDOTP = "/patient/resendotp";
export const DOCTORREGISTER = "/doctor/register";
export const DOCTERLOGIN = "/doctor/login";
export const DOCTERCHANGEPASS = "/doctor/changepassword";
export const DOCTERRFORGOTPASS = "/doctor/forgotpassword";
export const DOCTOROTPVERIFY = "/doctor/otpverify";
export const DOCTORRESENDOTP = "/doctor/resendotp";
export const DOCTORGETPROFILE = "/doctor/getProfile";
// cms api..................................
export const CMSABOUTUSAPI = "/cms/aboutus";
export const CMSTERMSCONDITIONAPI = "/cms/termsandcondition";
export const CMSPRIVACYPOLICYAPI = "/cms/privacypolicys";
export const CMSCONTACTUSAPI = "/cms/contactUs";
export const FAQSAPI = "/cms/faqs";

export const COMPLETEAPPOINTMENTLISTAPI = "/patient/complateAppointmentlist";
export const UPCOMINGAPPOINTMENTLISTAPI = "/patient/upcomingAppointment";
export const NOTIFICATIONAPI = "/patient/getNotification";
export const DOCTORNOTIFICATIONAPI = "/doctor/getNotification";
export const NOTIFICATIONAPICOUNT = "/patient/getNotificationCount";
export const DOCTORNOTIFICATIONAPICOUNT = "/doctor/getNotificationCount";
export const DELETENOTIFICATIONAPI = "/patient/notificationsDelete";
export const DOCTORDELETENOTIFICATIONAPI = "/doctor/notificationsDelete";
export const HEALTHCARDDATAAPI = "/patient/getHealthCard";
export const FAMILYMEMBERLIST = "/patient/listofFamilyMember";
export const PRESCRIPTIONLIST = "/patient/prescriptionList";
export const DOCTORLIST = "/patient/doctorList";
export const SOCIALLOGIN = "/patient/sociallogin";
export const SOCIALLOGINDOCTOR = "/doctor/socialLogin";
export const MASTERDATAAPI = "/masterdata/metadata";
export const PRESCRIPTIONMASTERAPI = "/masterdata/prescriptionMaster";
export const PATIENTSETNOTIFICATIONAPI = "/patient/setNotification";
export const DOCTORSETNOTIFICATIONAPI = "/doctor/setNotification";
export const PATIENTCARDLISTAPI = "/patient/getCard";
export const SAVECARDAPI = "/patient/saveCard";
export const GETSYMTOMSAPI = "/patient/getsymtoms";
export const EDITPROFILEAPI = "/patient/updateprofile";
export const XRayultrasound = "/doctor/noteSave";
export const CardiologyApi = "/doctor/noteSave";
export const CTscanApi = "/doctor/noteSave";
export const GeneralFormApi = "/doctor/noteSave";

export const OtherFormApi = "/doctor/noteSave";
export const GeneralPublicHealthFormApi = "/doctor/noteSave";
export const HIVFormApi = "/doctor/noteSave";
export const ClinicalNoteApi = "/doctor/noteSave";

export const ADDFAMILYMEMBER = "/patient/addfamilyMember";
export const RESCHEDULEAPPOINTMENTAPI = "/patient/reschedulebookappointment";
export const ADDHEALTHCARD = "/patient/addHealthCard";
export const SHOWSERVINGNUMBERAPI = "/patient/showServingNumber";
export const PENDINGAPPOINTMENTAPI = "/doctor/pendingAppointment";
export const UPCOMINGAPPOINTMENTAPI = "/doctor/upcomingAppointmaent";
export const GETCOMPLETEAPPOINTMENTAPI = "/doctor/getcompelteAppointmentList";
export const GETPAYMENTHISTORYAPI = "/doctor/paymentHistory";
export const PRESCRIPTIONREQUESTAPI = "/doctor/pendingPrescriptionRequest";
export const GETREQUESTEDPRESCRIPTIONAPI = "/doctor/getRequestedPrescription";
export const GETMEDICINEAPI = "/doctor/getMedicine";
export const AddMedicineApi = "/doctor/addMedicine";
export const ReferralDoctorApi = "/doctor/referralNote";
export const DoctorBookAPI = "/addressbook/doctorBooklist";
export const AddDoctorInformationApi = "/addressbook/updateAddressBook";
export const TowAddDoctorInformationAPi = "/addressbook/doctorInsertDoctorbook";
export const ProfessionalInfoApi = "/doctor/update";
export const AvailablityApi = "/doctor/getschedule";
export const VirtualTimesApi = "/doctor/doctorschedule";
export const IncompleteNotesApi = "/doctor/inCompleteAppointment";
export const SickNoteApi = "/doctor/noteSave";
export const FindMDHeadApi = "/patient/matchDoctorList";
export const AppoinmentCalenderApi = "/patient/getslot";
export const HealthCardApi = "/patient/getHealthCard";
export const SelectedPharmacyApi = "/addressbook/pharmacy";
export const SendtolebApi = "/addressbook/laboratory";
export const PrescriptionNotesApi = "/doctor/noteSave";
export const WaitingRoomssApi = "/doctor/listwaitingroompatient";
export const SelectedPharmacyPost = "/addressbook/pharmachSendEmail";

export const SendtolebPostApi = "/addressbook/sendformtoLab";
export const PymentPost = "/patient/addPatientWaitingRoom";
export const Pymentbookappointment = "/patient/bookappointment";

export const CorporateLoginApi = "/hospital/list";
export const PendingInsuranceApi = "/patient/insuranceList";
export const doctorjoinigwaitingRoom = "/doctor/joinigwaitingRoom";
export const patientjoinigwaitingRoom = "/patient/joinigwaitingRoom";
export const joinMeeting = "/doctor/joinMeeting";
export const WaitingRoomAPI = "/patient/showServingNumber";
export const patientjoinMeeting = "/patient/joinMeeting";
export const WaitingRoomjoinigAPI = "/patient/joinigwaitingRoom";

export const prescriptionAppointmentApi = "/patient/prescriptionAppointment";

export const EditPrescriptionNotesApi = "/doctor/editPrescription";

export const SickNotePaymentApi = "/patient/sickNotePayment";
export const UplodeImg = "/doctor/saveimagereports";
export const ConsultationHistoryAPI = "/doctor/appointmentHistory";

export const UpdetInformationApi = "/patient/updatefamilyMember";
export const RejectionApi = "/doctor/cancelAppointment";

export const RejactPrescription = "/doctor/prescriptionReject";
export const ApprovalPrescription = "/doctor/approvalPrescription";
export const RejectListApi = "/patient/prescriptionRejectList";
export const ReviewRating = "/patient/review-rating";
export const reschedulebookappointmentApi =
  "/patient/reschedulebookappointment";
export const doctorstartCallwaitingRoom = "/doctor/startCallwaitingRoom";

export const doctorwaitingRoomComplete = "/doctor/waitingRoomComplete";
export const patientwaitingRoomComplete = "/patient/waitingRoomComplete";
export const completeAppointment = "/doctor/completeAppointment";

export const getMedicineApi = "/patient/getMedicine";
export const StopTimeSet = "/addressbook/videoCallStopTime";
export const StopTimeGet = "/addressbook/videoStopTimeAppointmentGet";
export const GetRoomTime = "/patient/waitingRoomTime";
export const bookappointmentLink = "/patient/bookappointmentLink";

export const OntarioApi = "/doctor/OntarioProvinceCheck";
export const OntarioPatienttApi = "/patient/OntarioProvinceCheck";
export const Strype = "/doctor/verifyStripeAccount";
export const bankAccount = "/doctor/bankAccount";
export const getWaitingRoomPrice = "/price/getWaitingRoomPrice";
export const renewalSlot = "/patient/renewalSlot";
