import * as Yup from "yup";

export const LoginSchemas = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(4).required("Please enter your password "),
});

export const ForgotPasswordSchemas = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
});

export const YourProfileshemas = Yup.object({
  FullName: Yup.string()
    .trim()
    .min(2)
    .max(25)
    .required("Please enter your full name"),
  LastName: Yup.string()
    .trim()
    .min(2)
    .max(25)
    .required("Please enter your Last name"),
  date: Yup.string().required("Please enter your Age"),
  image: Yup.string().required("Please select your profile image"),
});

export const OtherInformationSchemas = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  Password: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .min(6)
    .required("Please enter your password"),
  phoneNumber: Yup.number().required("Please enter your number"),
  gender: Yup.string().required("Please select your gender"),
  ConfirmNewPassword: Yup.string()
    .required()
    .oneOf([Yup.ref("Password"), null], "Password must match"),
  Location: Yup.string().required("Please enter your password"),
});

export const ChangePasswor = Yup.object({
  CurrentPassword: Yup.string().required("Please enter  current password"),
  NewPassword: Yup.string().min(6).required("Please enter your password"),
  ConfirmNewPassword: Yup.string()
    .required("Please enter your confirm new password")
    .oneOf([Yup.ref("NewPassword"), null], "Password must match"),
});

// ------------------------------------DoctorRegister--------------------------------------------------//
export const DoctorRegisterSchemas = Yup.object({
  email: Yup.string().email().required("Please enter your email"),
  password: Yup.string().min(6).required("Please enter your password"),
  confirmPassword: Yup.string()
    .required("Please enter your Confirmnewpassword")
    .oneOf([Yup.ref("password"), null], "Password must match"),
});

export const CreateProfileDoctorSchemas = Yup.object({
  FullName: Yup.string().trim().min(2).required("Please enter your full name"),
  LastName: Yup.string().trim().min(2).required("Please enter your last name"),
  date: Yup.string().trim().required("Please enter your date"),
  Mobile: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .trim()
    .required("Please enter your Mobile"),
  FaxNumber: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .trim()
    .required("Please enter your faxnumber"),
  gender: Yup.string().required("Please select your gender"),
  image: Yup.string().required("Please select Image"),
});

export const HealthDataInformationSchemas = Yup.object({
  Weight: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter your  weight"),
  unitone: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please select  unit "),
  Height: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter your height"),
  unittow: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please select unit"),
  bmi: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter your bmi"),
  smoking: Yup.string().required("This field required"),
  alcohol: Yup.string().required("This field required"),
  marijauna: Yup.string().required("This field required"),
  // MedicalCondition: Yup.string().required("Please enter your MedicalCondition"),
  Allergies: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter your allergies"),
  // Medication: Yup.string().required("Please enter your Medication"),
});

export const Doctorregisterschemas = Yup.object({
  location: Yup.string().required("This field required"),
  city: Yup.string().required("This field required"),
  // postalCode: Yup.string().required("This field required"),
  state: Yup.string().required("This field required"),
  // country: Yup.string().required("This field required"),
  speciality: Yup.string().required("This field required"),
  qualification: Yup.string().required("This field required"),
  totalExperience: Yup.string().required("This field required"),
  // licence: [{ country: "India", licenceAuthority: "NMC", licensingNumber: "12345678" }, ],
});
export const AddBankAccountSchemas = Yup.object({
  Bank: Yup.string().trim().required("Please enter Bnak Name"),
  Account: Yup.string().trim().required("Please enter  Account Holder Name"),
  AccountNumber: Yup.number().required("Please enter your Account Number"),
  Code: Yup.string().trim().required("Please enter your Branch Code"),
});
export const AddfamilyMemberSchemas = Yup.object({
  FullName: Yup.string().min(2).max(25).required("Please enter your full name"),
  LastName: Yup.string().min(2).max(25).required("Please enter your last name"),
  // image: Yup.string().required("Please select profile image"),
  gender: Yup.string().required("Please select gender"),
  familymember: Yup.string().required("Please select a family member"),
  Age: Yup.string().required("Please enter your age"),
});
export const AddhealthcardSchemas = Yup.object({
  memberId: Yup.string().min(2).max(25).required("Please select member"),
  healthCardNumber: Yup.string().required("Please enter card number"),
  versionCode: Yup.string().required("Please enter versionCode"),
  cardHolderName: Yup.string().required("Please enter name"),
});
export const ContactUsSchemas = Yup.object({
  title: Yup.string().min(2).max(25).required("Please enter tittle"),
  description: Yup.string().required("Please enter description"),
});

export const AddDoctarSchemas = Yup.object({
  FullName: Yup.string()
    .trim()
    .min(2)
    .max(25)
    .required("Please enter your full name"),
  Speciality: Yup.string().required("Please enter speciality"),
  PhoneNumber: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter your phone number"),
  Email: Yup.string().email().required("Please enter your email"),
  Address: Yup.string().required("Please enter address"),
  Country: Yup.string().required("Please enter country"),
  City: Yup.string().required("Please enter city"),
  State: Yup.string().required("Please enter state"),
  Postalcode: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter postal code"),
  FaxNumber: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter fax number"),
  // FaxNumber: Yup.string().required("Please enter fax number"),
});
export const updateDoctarSchemas = Yup.object({
  FullName: Yup.string()
    .trim()
    .min(2)
    .max(25)
    .required("Please enter your full name"),
  Speciality: Yup.string().required("Please enter speciality"),
  PhoneNumber: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter your phone number"),
  Email: Yup.string().email().required("Please enter your email"),
  Address: Yup.string().required("Please enter address"),
  Country: Yup.string().required("Please enter country"),
  City: Yup.string().required("Please enter city"),
  State: Yup.string().required("Please enter state"),
  Postalcode: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter postal code"),
  FaxNumber: Yup.string()
    .trim()
    .matches(/^[^\s]*$/, "Remove spaces")
    .required("Please enter fax number"),
  // FaxNumber: Yup.string().required("Please enter fax number"),
});
// FullName: "",
// Speciality: "",
// PhoneNumber: "",
// Email: "",
// Address: "",
// Country: "",
// City: "",
// State: "",
// Postalcode: "",
// FaxNumber: "",
// Wabsite: "",
