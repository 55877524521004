import "./AddBankAccount.css";
import { useFormik } from "formik";
import { AddBankAccountSchemas } from "../../Schemas/index";
// import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
// import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
// import { GlobalContext } from "../../Context/GlabalContext";

import { useNavigate } from "react-router-dom";
import axios from "../../Constant/ApiUrl";
import { bankAccount } from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";
import { toast } from "react-toastify";
import { useState } from "react";
import Loader from "../../Constant/Loader";

const AddBankAccount = () => {
  const [loading, setloading] = useState(false);

  const initialValues = {
    Bank: "",
    Account: "",
    AccountNumber: "",
    Code: "",
  };

  const navigate = useNavigate();
  const header = Headers();

  const onSubmit = async () => {
    setloading(true);
    try {
      const res = await axios.post(
        bankAccount,
        {
          bankName: values.Bank,
          accountHolderName: values.Account,
          accountNumber: values.AccountNumber,
          routingNumberBranchCode: values.Code,
        },
        header
      );
      setloading(false);
      if (res.data.status === "success") {
        navigate("/Congratulation");
        toast.success(res.data.message);
        localStorage.setItem("isloginmtd", JSON.stringify(true));
      } else {
        toast.error(res.data.message);
      }
      // console.log(res);
    } catch (error) {
      // console.log(error);
      toast.error(error.response.data.message);
      setloading(false);
    }
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: AddBankAccountSchemas,
    onSubmit,
  });

  const handlenumChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setFieldValue("AccountNumber", value);
    }
  };

  return (
    <>
      <div className="All-Conatinor-perfect-divv">
        <div className="All-Containor-perfect-second-divv">
          <div className="Profile-extra-div">
            <div className="Profile-main-Your">
              <div className="navigate-back-main">
                <span
                  onClick={() => navigate(-1)}
                  style={{ cursor: "pointer" }}
                  // onClick={() => navigate(-1)}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_26_4543)">
                      <path
                        d="M7.828 11.0002H20V13.0002H7.828L13.192 18.3642L11.778 19.7782L4 12.0002L11.778 4.22217L13.192 5.63617L7.828 11.0002Z"
                        fill="#1E2D38"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_26_4543">
                        <rect width="24" height="24" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
                {/* <span className="back-skip-point">skip</span> */}
              </div>
              <span className="Order-history-span">Bank Account Details</span>
              <span className="Upload-profile">
                Add your bank account details below.
              </span>
            </div>
          </div>
          <form onSubmit={handleSubmit} className="second-div mt-4">
            <div className="allNumber-input-div-main">
              <div className="mt-5 all-input-areya">
                <div className="full-and-last-name">
                  <div>
                    <span className="">Bank</span>
                    <div className="full-name-bug-div">
                      <div className="text-Bank-fullname mt-2">
                        <input
                          className="i-t-a-Bank-itm"
                          type="text"
                          placeholder="Enter Bank Name"
                          name="Bank"
                          value={values.Bank}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {errors.Bank && touched.Bank ? (
                      <p className="mastu-to-eroor mt-2">{errors.Bank} </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-3">
                  <span className="">Account Holder</span>
                  <div className="full-name-bug-div">
                    <div className="text-Bank-fullname mt-2">
                      <input
                        className="i-t-a-Bank-itm"
                        type="text"
                        placeholder="Enter Account holder Name"
                        name="Account"
                        value={values.Account}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.Account && touched.Account ? (
                    <p className="mastu-to-eroor mt-2">{errors.Account} </p>
                  ) : null}
                </div>
                <div className="mt-3">
                  <span className="">Account Number</span>
                  <div className="full-name-bug-div">
                    <div className="text-Bank-fullname mt-2">
                      <input
                        className="i-t-a-Bank-itm"
                        type="text"
                        placeholder="Enter Account holder Name"
                        name="AccountNumber"
                        value={values.AccountNumber}
                        onBlur={handleBlur}
                        onChange={handlenumChange}
                      />
                    </div>
                  </div>
                  {errors.AccountNumber && touched.AccountNumber ? (
                    <p className="mastu-to-eroor mt-2">
                      {errors.AccountNumber}{" "}
                    </p>
                  ) : null}
                </div>
                <div className="mt-3">
                  <span className=""> Routing Number / Branch Code</span>
                  <div className="full-name-bug-div">
                    <div className="text-Bank-fullname mt-2">
                      <input
                        className="i-t-a-Bank-itm"
                        type="text"
                        placeholder="Enter Code"
                        name="Code"
                        value={values.Code}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.Code && touched.Code ? (
                    <p className="mastu-to-eroor mt-2">{errors.Code} </p>
                  ) : null}
                </div>
              </div>
              <div className="mt-4">
                <button type="submit" className="Btn-Register-karo">
                  Next
                </button>
              </div>
            </div>
          </form>
          <div>
            <img className="YourProfile-Code-next-blue" src={Blue} alt="" />
          </div>
        </div>
      </div>
      {loading && <Loader />}
    </>
  );
};

export default AddBankAccount;
