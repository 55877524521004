import "./SelectedPharmacy.css";
import React, { useState, useContext, useEffect } from "react";
import location from "../../Assets/Images/location_ic_sm@3x.png";
import Header from "../../Components/Header/Header";
import { Icons } from "../../Constant/Icons";
import { useLocation, useNavigate } from "react-router-dom";
import Fetchdata from "../../Constant/Fetchdata";
import axios from "../../Constant/ApiUrl";
import {
  SelectedPharmacyApi,
  SelectedPharmacyPost,
} from "../../Constant/Apiconstant";
import { Headers } from "../../Constant/Fetchdata";

const SelectedPharmacy = () => {
  const location = useLocation();
  const MoyaMoya = location?.state?.itm;

  const id = MoyaMoya?._id;

  // console.log(id, "MoyaMoya");
  const [loading, setloading] = useState(false);
  const [selectedcardi, setSelectedCardi] = useState("");
  // console.log(selectedcardi, "selectedcardi");
  const [selectedcard, setselectedcard] = useState({
    cardid: "",
    isSelected: null,
  });
  const navigate = useNavigate();
  const header = Headers();
  const { response } = Fetchdata(SelectedPharmacyApi, { header }, "GET");
  // console.log(response, "response");
  const toggleSelection = (currentIndex, cardId, item) => {
    setSelectedCardi(item);
    // console.log(item, "item");
    setselectedcard((prevSelectedCard) => {
      const isSelected =
        prevSelectedCard.isSelected === currentIndex ? null : currentIndex;

      return { isSelected, cardid: isSelected ? cardId : null };
    });
  };

  const hendelPhae = (item) => {
    // console.log(item, "item");
    navigate("/PrescriptionNotes", {
      state: {
        item,
        id,
      },
    });
  };

  // const onSubmit = async () => {
  //   setloading(true);
  //   try {
  //     const res = await axios.post(
  //       SelectedPharmacyPost,
  //       {
  //         appointmentID: "6507efdd965a103fc95af7d4",
  //         email: response?.data[0]?.email,
  //       },
  //       header
  //     );
  //     setloading(false);
  //     console.log(res, "jvcccfh");
  //   } catch (error) {
  //     console.log(error);
  //     setloading(false);
  //   }
  // };

  // useEffect(() => {
  //   if (response?.data) {
  //     setFinedmd(response.data.docs);
  //   }
  // }, [response]);

  return (
    <>
      <Header
        onClick={() => navigate(-1)}
        text={"Select pharmacy "}
        style={{ color: "white" }}
      />
      <section>
        <div className="">
          {response?.data?.map((item, index) => {
            return (
              <>
                <div
                  key={index}
                  onClick={() => toggleSelection(index, item, item.id)}
                  className="FindMD-img-contain"
                >
                  <div>
                    <img className="Cardimg-docter" src={item.image} alt="" />
                  </div>
                  <div className="Dorothy-Lucas-sapn-div">
                    <div className="Height-awy-main-div">
                      <div className="isSelected-main-div">
                        <span className="Dorothy-sapn">{item.name}</span>
                        <div className="dustbin-logo-black">
                          <span onClick={() => toggleSelection(index, item)}>
                            {selectedcard.isSelected === index
                              ? Icons.cardselecticon
                              : null}
                          </span>
                        </div>
                      </div>
                      <div className="">
                        <img className="time-img-css" src={location} alt="" />
                        <span className="Am-pm-span">{item.streetAddress}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="  Select-main-section">
                  <button
                    onClick={() => hendelPhae(item)}
                    className="Add-search-New"
                  >
                    Select
                  </button>
                </div>
              </>
            );
          })}
        </div>
      </section>
    </>
  );
};

export default SelectedPharmacy;
