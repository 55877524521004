import React, { useState, useContext, useEffect } from "react";

import { useFormik } from "formik";
import { YourProfileshemas } from "../../../Schemas/index";
import profilePic from "../../../Assets/Images/default_pic_ic@3x.png";
import SandIcon from "../../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../../Assets/Images/blue_bg@3x.png";
import { GlobalContext } from "../../../Context/GlabalContext";
import OtherInformation from "../../OtherInformation/OtherInformation";
import HealthDataInformation from "../../HealthDataInformation/HealthDataInformation";
import { useNavigate } from "react-router-dom";
import Input from "../../../Components/Input/Input";
import { Icons } from "../../../Constant/Icons";
import { IS_LOGGED_IN, KEY_USER_DATA } from "../../../Redux/Appconstant";
import { store } from "../../../Redux/store";
import { toast } from "react-toastify";
import { setSessionField } from "../../../Redux/SessionAction";

const UpdetProfile = () => {
  const [Image, setImage] = useState({ img: "", file: null });
  // console.log(Image, "Image");
  const { steps, setsteps, settokenexpire, setUpdetDataProfile } =
    useContext(GlobalContext);

  // console.log(UpdetDataProfile, "UpdetDataProfile");
  const Mydata = store.getState().session[KEY_USER_DATA];
  // console.log(profimg, "profimg");
  // console.log(new Date(Mydata.basicInfo.age), "Mydata");
  const initialValues = {
    FullName: "",
    LastName: "",
    image: "",
    Age: "",
  };

  useEffect(() => {
    setFieldValue("FullName", Mydata.basicInfo.firstName);
    setFieldValue("LastName", Mydata.basicInfo.lastName);
    setImage({ img: Mydata.basicInfo.image });
    setFieldValue("Age", Mydata.basicInfo.age);
  }, [Mydata]);

  const onSubmit = () => {
    // console.log("click");
  };

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: YourProfileshemas,
    onSubmit,
  });

  // console.log(Mydata.basicInfo.age, "Age");
  const calculateage = (dob) => {
    let today = new Date();
    let birthdatobj = new Date(dob);
    let age = today.getFullYear() - birthdatobj.getFullYear();
    const month = today.getMonth() - birthdatobj.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthdatobj.getDate())) {
      age--;
    }
    return age;
  };

  const UpdetData = {
    FullName: values.FullName,
    LastName: values.LastName,
    image: Image,
    Age: values.Age,
  };

  const navigate = useNavigate();
  const dodata = () => {
    let age = calculateage(values.Age);
    if (age < 18) {
      toast.error("Age must be 18 +");
    } else if (age > 65) {
      toast.error("Age must be less then 65");
    } else {
      setUpdetDataProfile(UpdetData);
      navigate("/editprofile");
    }
  };

  const handleAge = (e) => {
    const age = new Date(e.target.value);
    // console.log(age);
    setFieldValue("Age", calculateage(age));
  };

  return (
    <>
      <form
        onSubmit={handleSubmit}
        className="All-Containor-perfect-second-divv"
      >
        <div className="Profile-extra-div">
          <div className="Profile-main-Your">
            <span
              style={{ cursor: "pointer", width: "max-content" }}
              onClick={() => {
                if (Mydata?.isCompleteProfile) {
                  navigate("/Profile");
                } else {
                  store.dispatch(setSessionField(IS_LOGGED_IN, false));
                  store.dispatch(setSessionField(KEY_USER_DATA, {}));
                  settokenexpire(false);
                  localStorage.clear();
                  navigate("/");
                }
              }}
            >
              {Icons.backarrowblack}
            </span>
            <h6 className="mt-2">Step {steps} to 3</h6>
            <span className="Order-history-span">Your Profile</span>
            <span className="Upload-profile">
              Upload your profile to get better consultants from doctor.
            </span>
          </div>
        </div>
        <div className="second-div mt-4">
          <div className="all-input-div-main">
            <div className="Ht-ml-for-imput">
              <label className="" htmlFor="mal">
                <img src={profilePic} alt="" className="pic-on-add" />
                <div className="SandIcon-addeting-div">
                  <img className="Cemara-te-img" src={SandIcon} alt="" />
                </div>
              </label>
              <input
                id="mal"
                type="file"
                onChange={(e) => {
                  setImage({
                    img: URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0],
                  });
                  // setprofimg(e.target.files[0]);
                }}
                style={{ display: "none" }}
                className="Malte-pal-cls"
              />
              <img
                src={Image?.img}
                alt=""
                width="100px"
                height="100px"
                className="add-kiya-muja"
              />
            </div>
            <br />
            {/* {errors.image && touched.image ? (
                  <p className="mastu-to-eroor mt-2 ">{errors.image} </p>
                ) : null} */}
            <div className="mt-5 all-input-areya">
              <div className="full-and-last-name">
                <div className="Full-all-Name-input">
                  <span className="">Full name</span>
                  <div className="full-name-bug-div">
                    <div className="text-boxx-fullname mt-2">
                      <span>{Icons.user}</span>
                      <input
                        className="i-t-a-host-itm"
                        type="text"
                        placeholder="Full name"
                        name="FullName"
                        value={values.FullName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.FullName && touched.FullName ? (
                    <p className="mastu-to-eroor mt-2">{errors.FullName} </p>
                  ) : null}
                </div>
                <div>
                  <span className="">Last name</span>
                  <div className="full-name-bug-div">
                    <div className="text-boxx-fullname mt-2">
                      <span>{Icons.user}</span>
                      <input
                        className="i-t-a-host-itm"
                        type="text"
                        placeholder="Last name"
                        name="LastName"
                        value={values.LastName}
                        onBlur={handleBlur}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {errors.LastName && touched.LastName ? (
                    <p className="mastu-to-eroor mt-2">{errors.LastName} </p>
                  ) : null}
                </div>
              </div>
              <div className="input-date-main-div mt-4">
                <span>Age</span>
                <input
                  placeholder="13 May 1995"
                  className="Date-of-Birth-input"
                  type="date"
                  name="Age"
                  max="9999-12-31"
                  // value={values.Age}
                  onBlur={handleBlur}
                  onChange={handleAge}
                />
                {errors.Age && touched.Age ? (
                  <p className="mastu-to-eroor">{errors.Age} </p>
                ) : null}
              </div>
            </div>
            <div className="mt-4 mb-3">
              <button
                onClick={dodata}
                type="submit"
                className="Btn-Register-karo"
              >
                Next
              </button>
            </div>
          </div>
        </div>
        <div>
          <img className="YourProfile-next-blue" src={Blue} alt="" />
        </div>
      </form>
    </>
  );
};

export default UpdetProfile;
