import React, { useState, useContext, useEffect } from "react";
import "./EditProfileDoctor.css";
import { useFormik } from "formik";
import { CreateProfileDoctorSchemas } from "../../Schemas/index";
import profilePic from "../../Assets/Images/default_pic_ic@3x.png";
import SandIcon from "../../Assets/Images/camera_ic_yellow@3x.png";
import Blue from "../../Assets/Images/blue_bg@3x.png";
import { GlobalContext } from "../../Context/GlabalContext";
import Fax from "../../Assets/Images/fax_ic@3x.png";
import { useNavigate } from "react-router-dom";
import { Icons } from "../../Constant/Icons";
import Fetchdata from "../../Constant/Fetchdata";
// import Header from "../../Components/Header/Header";
import { DOCTORGETPROFILE } from "../../Constant/Apiconstant";
import { toast } from "react-toastify";
// import { Colors } from "../../Constant/Colors";
import ProfessionalInfo from "../ProfessionalInfo/ProfessionalInfo";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import { store } from "../../Redux/store";
import { IS_LOGGED_IN, KEY_USER_DATA } from "../../Redux/Appconstant";
import { setSessionField } from "../../Redux/SessionAction";

const EditProfileDoctor = () => {
  const Mydata = store.getState().session[KEY_USER_DATA];

  const navigate = useNavigate();

  const onSubmit = () => {
    // console.log(onSubmit);
  };
  const { setDocdata } = useContext(GlobalContext);
  // const { steps, setsteps, setDoctorRegisterdata } = useContext(GlobalContext);

  const { steps, setsteps, UpdetDataProfile, setUpdetDataProfile } =
    useContext(GlobalContext);

  // console.log(UpdetDataProfile, "UpdetDataProfile");
  const [Image, setImage] = useState({ image: "" });
  const [profimg, setprofimg] = useState();
  // console.log(profimg, "imgfile");
  const [selected, setselected] = useState("");
  const [doctorBook, setDoctorBook] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  // console.log(startDate, "startDateavo");
  // const [date, setDate] = useState();
  // console.log(doctorBook, "njnjednjcnd");
  const initialValues = {
    FullName: "",
    LastName: "",
    image: "",
    date: "",
    gender: "",
    Mobile: "",
    FaxNumber: "",
  };

  const { response } = Fetchdata(DOCTORGETPROFILE, {}, "GET");

  useEffect(() => {
    if (response?.data) {
      setDoctorBook(response?.data?.basicInfo);
    }
  }, [response]);
  // console.log(response, "response");

  useEffect(() => {
    setFieldValue("FullName", doctorBook.fullName);
    setFieldValue("LastName", doctorBook.lastName);
    setFieldValue("gender", doctorBook.gender);
    // setFieldValue("date", moment(doctorBook.dob).format("YYYY-MM-DD"));
    setFieldValue("Mobile", doctorBook.phoneNumber);
    setFieldValue("FaxNumber", doctorBook.faxNumber);
    setImage({ img: doctorBook.image });
    if (doctorBook?.dob) {
      setStartDate(moment(doctorBook?.dob, "DD-MM-YYYY").toDate());
    }
    setselected(doctorBook.gender);
  }, [doctorBook]);

  useEffect(() => {
    const inputDate = doctorBook?.dob;
    if (inputDate) {
      const parsedDate = moment(inputDate, "DD-MM-YYYY");
      if (parsedDate.isValid()) {
        setStartDate(parsedDate.toDate());
      } else {
        // console.error("Invalid date format:", inputDate);
        setStartDate(new Date());
      }
    } else {
      // console.error("Doctor's date of birth is undefined");
      setStartDate(new Date());
    }
  }, [doctorBook?.dob]);

  // console.log(inputDate, "inputDate");
  // useEffect(() => {
  //   const inputDate = doctorBook?.dob;
  //   if (inputDate) {
  //     const parsedDate = moment(inputDate, "DD-MM-YYYY");

  //     if (parsedDate.isValid()) {
  //       setStartDate(parsedDate.toDate());
  //     } else {
  //       console.error("Invalid date format:", inputDate);
  //     }
  //   }
  // }, [doctorBook?.dob]);
  //  setStartDate(doctorBook?.dob);

  const {
    values,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
    errors,
    setFieldValue,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: CreateProfileDoctorSchemas,
    onSubmit,
  });

  const calculateage = (dob) => {
    let today = new Date();
    let birthdatobj = new Date(dob);
    let age = today.getFullYear() - birthdatobj.getFullYear();
    const month = today.getMonth() - birthdatobj.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthdatobj.getDate())) {
      age--;
    }
    return age;
  };

  const dodata = () => {
    let age = calculateage(startDate);
    // console.log(age, "age");
    if (age < 18) {
      toast.error("Age must be 18 +");
    } else if (age > 65) {
      toast.error("Age must be less then 65");
    } else {
      setDocdata(maindata);
      setsteps(2);
    }
  };

  // console.log(date,"jjjjjjjj");
  const maindata = {
    FullName: values.FullName,
    LastName: values.LastName,
    image: Image,
    date: startDate,
    gender: selected,
    Mobile: values.Mobile,
    FaxNumber: values.FaxNumber,
  };

  return (
    <>
      <div className="w-100">
        {steps == 1 ? (
          <div className="All-Containor-perfect-second-divv">
            <div className="Profile-extra-div">
              <div className="Profile-main-Your">
                <span
                  onClick={() => {
                    if (Mydata?.isCompleteProfile) {
                      navigate(-1);
                    } else {
                      store.dispatch(setSessionField(IS_LOGGED_IN, false));
                      store.dispatch(setSessionField(KEY_USER_DATA, {}));
                      // settokenexpire(false);
                      localStorage.clear();
                      navigate("/");
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  {Icons.backarrowblack}
                </span>
                <h6 className="mt-2">Step {steps} to 2</h6>
                <span className="Order-history-span">Edit profile</span>
                <span className="Upload-profile">
                  Enter your information below to create profile.
                </span>
              </div>
            </div>

            <form onSubmit={handleSubmit} className="second-div">
              <div className="all-input-CreateProfileDoctor-div-main">
                <div className="Ht-ml-for-imput">
                  <label className="" htmlFor="mal">
                    <img src={profilePic} alt="" className="pic-on-add" />
                    <div className="SandIcon-addeting-div">
                      <img className="Cemara-te-img" src={SandIcon} alt="" />
                    </div>
                  </label>
                  <input
                    id="mal"
                    type="file"
                    onChange={(e) => {
                      setImage({ img: URL.createObjectURL(e.target.files[0]) });
                      setprofimg(e.target.files[0]);
                    }}
                    className="Malte-pal-cls"
                    style={{ display: "none" }}
                  />
                  <img
                    src={Image?.img}
                    alt=""
                    width="100px"
                    height="100px"
                    className="add-kiya-muja"
                  />
                </div>
                {/* {errors.image && touched.image ? (
                <p className="mastu-to-eroor mt-2 ">{errors.image} </p>
              ) : null} */}
                <div className="mt-5 all-input-areya">
                  <div className="full-and-last-name-edit">
                    <div className="Full-all-Name-input">
                      <span className="">Full name</span>
                      <div className="full-name-bug-div">
                        <div className="text-CreateProfileDoctor-fullname mt-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_18_660)">
                              <path
                                d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                                fill="#211B24"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_18_660">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <input
                            className="i-t-a-move-host-itm"
                            type="text"
                            placeholder="Full name"
                            name="FullName"
                            value={values.FullName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {errors.FullName && touched.FullName ? (
                        <p className="mastu-to-eroor mt-2">
                          {errors.FullName}{" "}
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <span className="">Last name</span>
                      <div className="full-name-bug-div">
                        <div className="text-CreateProfileDoctor-fullname mt-2">
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clipPath="url(#clip0_18_660)">
                              <path
                                d="M4 22C4 19.8783 4.84285 17.8434 6.34315 16.3431C7.84344 14.8429 9.87827 14 12 14C14.1217 14 16.1566 14.8429 17.6569 16.3431C19.1571 17.8434 20 19.8783 20 22H18C18 20.4087 17.3679 18.8826 16.2426 17.7574C15.1174 16.6321 13.5913 16 12 16C10.4087 16 8.88258 16.6321 7.75736 17.7574C6.63214 18.8826 6 20.4087 6 22H4ZM12 13C8.685 13 6 10.315 6 7C6 3.685 8.685 1 12 1C15.315 1 18 3.685 18 7C18 10.315 15.315 13 12 13ZM12 11C14.21 11 16 9.21 16 7C16 4.79 14.21 3 12 3C9.79 3 8 4.79 8 7C8 9.21 9.79 11 12 11Z"
                                fill="#211B24"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_18_660">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                          <input
                            className="i-t-a-move-host-itm"
                            type="text"
                            placeholder="Last name"
                            name="LastName"
                            value={values.LastName}
                            onBlur={handleBlur}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      {errors.LastName && touched.LastName ? (
                        <p className="mastu-to-eroor mt-2">
                          {errors.LastName}{" "}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  <div className="Gender-Male-Female-div mt-4">
                    <span>Gender</span>
                    <div className="Gender-main-div mt-2">
                      <button
                        className={
                          selected == "male"
                            ? "Female-btn-mainactive "
                            : "Female-btn-main"
                        }
                        type="button"
                        onClick={() => {
                          setFieldValue("gender", "male");
                          setselected("male");
                        }}
                      >
                        Male
                      </button>
                      <button
                        className={
                          selected == "female"
                            ? "Female-btn-mainactive "
                            : "Female-btn-main"
                        }
                        type="button"
                        onClick={() => {
                          setFieldValue("gender", "female");
                          setselected("female");
                        }}
                      >
                        Female
                      </button>
                    </div>
                    {errors.gender && touched.gender ? (
                      <p className="mastu-to-eroor">{errors.gender}</p>
                    ) : null}
                  </div>
                  <div className="input-date-main-div mt-4">
                    <span>Date of Birth</span>
                    <DatePicker
                      className="Date-of-Birth-input-startDate"
                      selected={startDate || ""}
                      onChange={(date) => setStartDate(date)}
                    />
                    {/* {errors.date && touched.date ? (
                      <p className="mastu-to-eroor">{errors.date} </p>
                    ) : null} */}
                  </div>
                  <div className="mt-2">
                    <span className="">Mobile</span>
                    <div className="full-name-bug-div">
                      <div className="text-Phone-fullname mt-2">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clipPath="url(#clip0_12_1283)">
                            <path
                              d="M7 4V20H17V4H7ZM6 2H18C18.2652 2 18.5196 2.10536 18.7071 2.29289C18.8946 2.48043 19 2.73478 19 3V21C19 21.2652 18.8946 21.5196 18.7071 21.7071C18.5196 21.8946 18.2652 22 18 22H6C5.73478 22 5.48043 21.8946 5.29289 21.7071C5.10536 21.5196 5 21.2652 5 21V3C5 2.73478 5.10536 2.48043 5.29289 2.29289C5.48043 2.10536 5.73478 2 6 2ZM12 17C12.2652 17 12.5196 17.1054 12.7071 17.2929C12.8946 17.4804 13 17.7348 13 18C13 18.2652 12.8946 18.5196 12.7071 18.7071C12.5196 18.8946 12.2652 19 12 19C11.7348 19 11.4804 18.8946 11.2929 18.7071C11.1054 18.5196 11 18.2652 11 18C11 17.7348 11.1054 17.4804 11.2929 17.2929C11.4804 17.1054 11.7348 17 12 17Z"
                              fill="black"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_12_1283">
                              <rect width="24" height="24" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                        <input
                          className="i-t-a-Phone-itm"
                          type="number"
                          placeholder="Mobile"
                          name="Mobile"
                          value={values.Mobile}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {errors.Mobile && touched.Mobile ? (
                      <p className="mastu-to-eroor">{errors.Mobile} </p>
                    ) : null}
                  </div>
                  <div className="mt-2">
                    <span className="">Fax Number</span>
                    <div className="full-name-bug-div">
                      <div className="text-Phone-fullname mt-2">
                        <img className="Fax-img-main" src={Fax} alt="" />

                        <input
                          className="i-t-a-Phone-itm"
                          type="number"
                          placeholder="Fax Number"
                          name="FaxNumber"
                          value={values.FaxNumber}
                          onBlur={handleBlur}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {errors.FaxNumber && touched.FaxNumber ? (
                      <p className="mastu-to-eroor">{errors.FaxNumber} </p>
                    ) : null}
                  </div>
                </div>
                <div className="mt-5 mb-5">
                  <button
                    type="submit"
                    className="Btn-Register-karo-CreateProfileDoctor"
                    onClick={dodata}
                  >
                    Next
                  </button>
                </div>
              </div>
            </form>
            <div>
              <img
                className="CreateProfileDoctor-next-blue"
                src={Blue}
                alt=""
              />
            </div>
          </div>
        ) : steps == 2 ? (
          <ProfessionalInfo />
        ) : null}
      </div>
    </>
  );
};

export default EditProfileDoctor;
